<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="键值">
              <a-input
                v-decorator="[
                  'value',
                  { rules: [{ required: true, message: '请输入' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="图标">
              <a-input v-decorator="['icon']" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="排序">
              <a-input-number
                :min="0"
                style="width: 100%"
                v-decorator="['sort']"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="背景图片">
              <a-input v-decorator="['background']" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="所属字典">
              <a-cascader
                placeholder=""
                changeOnSelect
                v-decorator="[
                  'pid',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                :options="dataDictTree"
                :fieldNames="{
                  label: 'name',
                  value: 'key',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { add } from "@/api/setting/data-dict";

import { mapState } from "vuex";

export default {
  name: "addDataDict",

  data() {
    return {
      form: this.$form.createForm(this),
    };
  },

  computed: {
    ...mapState("setting", ["dataDict"]),
    dataDictTree() {
      return [
        {
          id: "根字典",
          key: "根字典",
          name: "根字典",
          value: "根字典",
          pid: null,
          children: this.dataDict,
        },
      ];
    },
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let pid = values.pid[values.pid.length - 1];

          if (pid === "根字典") {
            pid = undefined;
          }

          add({
            ...values,
            pid,
          }).then(() => {
            this.form.resetFields();
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
